import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/repository/src/layouts/PageLayout.js";
import Link from '../components/Link';
import enforcementAgencyServicesUserGuidePdf from '../files/enforcement-agency-services-587-user-guide.pdf';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`YPO – Dynamic Purchasing System - Enforcement Agent Services`}</h2>
    <h2>{`Using the DPS`}</h2>
    <p>{`As a Public Authority in England and Wales you can access this official OJEU procurement system and
use the DPS to conduct a mini competition amongst the approved list of suppliers.`}</p>
    <h2>{`YPO will provide you with:`}</h2>
    <ul>
      <li parentName="ul">{`Enforcement Agency Services User Guide;`}</li>
      <li parentName="ul">{`Access Agreement;`}</li>
      <li parentName="ul">{`Customer Registration Form; and`}</li>
      <li parentName="ul">{`Enforcement Agency Supplier List.`}</li>
    </ul>
    <p>{`There is an access fee of £750.00 to cover all the administration and the complete management of the
process which is only payable when you sign the Access Agreement YPO manages the process from start
to finish.`}</p>
    <p>{`The services provided can be found on page 6 of the user guide.`}</p>
    <h2>{`Selection Process by Mini Competition`}</h2>
    <p>{`To award a contract through the DPS you will need to undertake a mini competition, which will be
fully managed and facilitated by YPO via the e-procurement portal.`}</p>
    <p>{`You will tailor the mini competition to your authority, local requirements and your service
specification.`}</p>
    <h2>{`The mini competition can consist of:`}</h2>
    <ul>
      <li parentName="ul">{`Tailored specification and questions requiring a response submission document by suppliers;`}</li>
      <li parentName="ul">{`Presentation and Interview; and`}</li>
      <li parentName="ul">{`Price Element maximum 10% (other debts).`}</li>
    </ul>
    <p>{`YPO will manage the instructions to bidders and all clarifications.`}</p>
    <p>{`The review of submissions, specification, evaluation criteria and decision making remains with you,
and YPO will manage all the administration and will advise you throughout the process alongside your
corporate procurement team if required.`}</p>
    <h2>{`Mini Competition – Questions`}</h2>
    <p>{`YPO have a question library of pre-determined questions which test every aspect of the required
services, so you can either pick from the library or include your own questions that you feel will
suit your authority requirement the best.`}</p>
    <h2>{`Interview and Presentation - Optional`}</h2>
    <p>{`You are able to include an Interview and Presentation section in the mini competition as appropriate
to your requirements.`}</p>
    <h2>{`Informing Suppliers and Providing Feedback`}</h2>
    <p>{`YPO will handle all administration, and will issue all notices to both successful and unsuccessful
suppliers taking part in the mini competition.`}</p>
    <p>{`In addition, YPO will provide feedback to unsuccessful suppliers and will manage any disputes.`}</p>
    <p>{`YPO will also undertake the Contract Award process which can be for a period of up to 4 years.`}</p>
    <h2>{`Download the User Guide`}</h2>
    <p>{`Click the link to download a copy of the `}<Link href={enforcementAgencyServicesUserGuidePdf} mdxType="Link">{`Enforcement Agency Services User Guide`}</Link>{`.`}</p>
    <h2>{`Step by Step Summary Guide`}</h2>
    <p>{`To see the step by step guide go to page 8 of the user guide.`}</p>
    <h2>{`Contact YPO`}</h2>
    <p>{`Contact the YPO team direct by clicking this link:
`}<a parentName="p" {...{
        "href": "mailto:enforcementagencyservices@ypo.co.uk"
      }}>{`enforcementagencyservices@ypo.co.uk`}</a></p>
    <h2>{`Contact the Jacobs Team`}</h2>
    <p>{`For more information on how to access this framework, please contact
`}<a parentName="p" {...{
        "href": "mailto:sales@jacobsenforcement.com"
      }}>{`sales@jacobsenforcement.com`}</a>{` or telephone the team on
`}<a parentName="p" {...{
        "href": "tel:01516504979"
      }}>{`0151 650 4979`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      